@use "styles/mixins/setFonts" as *;
@use "styles/variables/colors/colors";

.footer-link {
  width: fit-content;
  padding: 4px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.footer {
  background-color: #002D2D;
  position: relative;

  &__container {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }

  &__left {
    max-width: 660px;
    width: 100%;
    margin-right: 25px;
    padding-bottom: 25px;
  }

  &__left-container {
    display: flex;
    justify-content: space-between;
    padding: 40px 0 70px;
    flex-wrap: wrap;

    section:not(:last-child) {
      margin-bottom: 20px;
      margin-right: 30px;
    }
  }

  &__right {
    padding-left: 80px;
    border-left: 1px solid #F1F2F3;
    min-width: 260px;
  }
}

.footer-block {
  &__header {
    @include setFonts("Roboto", 1.25rem, 1.75rem, 400);
    color: #FFFFFF;
    margin-bottom: 25px;
  }

  &__list {
    display: flex;
    flex-direction: column;


    li:not(:last-child) {
      margin-bottom: 15px;

    }
  }

  &__link {
    @include setFonts("Lato", 0.875rem, 1.25rem, 400);
    color: #FFFFFF;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }

  &__icon-wrapper {
    position: relative;
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 100px;
    border: 1px solid #F1F2F3;
    margin-right: 15px;
  }
}

.contact-us {
  padding: 40px 0 30px;

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__link {
    @include setFonts("Lato", 0.875rem, 1.25rem, 400);
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #FFFFFF;

    svg {
      margin-right: 10px;
    }
  }
}

.link-external {
  position: relative;
  padding-right: 22px;

  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 7px);
    right: 0;
    width: 14px;
    height: 14px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/common/icons/external-link.svg");
  }
}

.info-links {
  &__list {
    flex-wrap: wrap;
    align-items: center;
  }

  &__link {
    @include setFonts("Lato", 0.875rem, 1.25rem, 700);
    color: #FFFFFF;
    text-decoration: none;
  }
}

.footer__copyright {
  @include setFonts("Lato", 0.875rem, 1.25rem, 400);
  display: flex;
  justify-content: flex-end;
  color: #E4E5E7;
}


.switcher__wrapper {
  order: 3;
  margin-top: 75px;
}
.info-links__item {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}


@media screen and (max-width: 768px) {
  .footer {
    &__left-container {
      justify-content: space-evenly;
    }

    &__right {
      padding-left: 30px;
      min-width: 220px;
    }

    &__follow-us {
      order: 3;

      .footer-block {
        //&__header {
        //  display: none;
        //}

        &__link-text {
          display: none;
        }

        &__list {
          flex-direction: row;
          width: 260px;
          justify-content: space-between;
          margin: 0 auto;
        }
      }
    }
  }
}

@media screen and (max-width: 669px) {
  .footer {
    &__container {
      flex-direction: column;
    }

    &__right {
      border-left: none;
      border-top: 1px solid #F1F2F3;
      padding-left: 0;
    }

  }

  .contact-us {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .footer-block {
      &__header {
        display: none;
      }
    }
  }

  .info-links {
    order: 2;

    &__list {
      flex-direction: column;
      justify-content: flex-start;
    }

    &__item {
      display: flex;
      width: 100%;
    }
  }

  .switcher__wrapper {
    width: 100%;
    order: 3;
    display: flex;
    justify-content: center;
  }

  .footer__copyright {
    position: absolute;
    bottom: 15px;
    padding: 0 15px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .contact-us {
    &__list {
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 430px) {
  .footer__left-container {
    justify-content: flex-start;
  }
}